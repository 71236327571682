import React, { useState, useMemo, useCallback, useRef } from 'react';
import EventCard, { EventCardProps } from '../Card/EventCard/EventCard';
import Pagination from '../Pagination/Pagination';
import { ImageProps } from 'components/Image/Image';
import './listings.scss';

type PageType = 'article' | 'past-exhibition';

interface BaseListingItem {
  id: string;
  title: string;
  image: ImageProps;
}

export interface ArticleProps extends BaseListingItem {
  publishedAt: string;
  hasVideo: boolean;
  url: string;
}

export interface ExhibitionProps extends BaseListingItem {
  startsAt: string;
  endsAt: string;
  pageType: string;
  payingType: string;
  path: string;
}

export interface ListingPageProps {
  items: ArticleProps[] | ExhibitionProps[];
  itemsPerPage: number;
  formatDate: (date: string) => string;
  formatDateRange: (startDate: string, endDate: string) => string;
  pageType: PageType;
}

const mapListingItemToEventCardProps = (
  item: ArticleProps | ExhibitionProps,
  formatDate: (date: string) => string,
  formatDateRange: (startDate: string, endDate: string) => string,
  pageType: PageType
): EventCardProps => {
  const baseProps = {
    title: item.title,
    image: item.image
  };

  if (pageType === 'article') {
    const articleItem = item as ArticleProps;
    return {
      ...baseProps,
      date: formatDate(articleItem.publishedAt),
      link: articleItem.url,
      hasVideo: articleItem.hasVideo
    };
  } else {
    const exhibitionItem = item as ExhibitionProps;
    return {
      ...baseProps,
      date: formatDateRange(exhibitionItem.startsAt, exhibitionItem.endsAt),
      label: exhibitionItem.pageType,
      free: exhibitionItem.payingType?.toLowerCase() === 'free',
      link: exhibitionItem.path
    };
  }
};

export default function Listings({
  items,
  itemsPerPage,
  formatDate,
  formatDateRange,
  pageType
}: ListingPageProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const listingRef = useRef<HTMLElement>(null);

  const paginatedItems = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return items.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, items, itemsPerPage]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    if (listingRef.current) {
      const topDist = listingRef.current.getBoundingClientRect().top + window.pageYOffset - 92;
      window.scrollTo({ behavior: 'smooth', top: topDist });
    }
  }, []);

  const listingTitle = pageType === 'article' ? 'RA Magazine' : 'Past Exhibitions';

  return (
    <section className={`listings-page listings-page--${pageType}`} ref={listingRef}>
      <h1 className="listings-page__title">{listingTitle}</h1>

      <div className="listings__list">
        {paginatedItems.map((item) => (
          <div key={item.id} className="listings__item">
            <EventCard
              {...mapListingItemToEventCardProps(item, formatDate, formatDateRange, pageType)}
              gaTags={{ type: 'card', area: 'listings_card' }}
            />
          </div>
        ))}
      </div>

      <Pagination
        totalCount={items.length}
        pageSize={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </section>
  );
}
