import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Cta, { CtaProps } from '../Cta/Cta';
import CategoryCard, { CategoryCardProps } from '../Card/CategoryCard/CategoryCard';
import EventCard, { EventCardProps } from '../Card/EventCard/EventCard';
import { useMobile } from '../../hooks/mediaHook';
import ReviewCard, { ReviewCardProps } from '../Card/ReviewCard/ReviewCard';
import './carousel.scss';
import { GALinkProps } from '../../defaultProps';
import { formatAnchorName } from '../../util';
import Icon from '../Icons/Icons';

export type CarouselProps = {
  type: string;
  title?: string;
  anchorName?: string;
  cards: EventCardProps[] | CategoryCardProps[] | ReviewCardProps[];
  cta?: CtaProps;
  color?: string;
  stacked?: boolean;
};

export default function Carousel({
  type,
  title,
  anchorName,
  cards,
  cta,
  stacked = false
}: CarouselProps): ReactElement {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps'
  });
  const [prevButtonEnabled, setPrevButtonEnabled] = useState<boolean>();
  const [nextButtonEnabled, setNextButtonEnabled] = useState<boolean>();
  const isMobile = useMobile();
  const isTablet = useMobile('1024px');

  const onSelect = useCallback((): void => {
    if (!emblaApi) return;
    setPrevButtonEnabled(emblaApi.canScrollPrev());
    setNextButtonEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setNextButtonEnabled, setPrevButtonEnabled]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.reInit();
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  function getGaTags(carouselType: string, element: 'ui' | 'card'): GALinkProps {
    switch (carouselType) {
      case 'category':
        return {
          type: element,
          area: 'event_categories_carousel'
        };
      case 'review':
        return {
          type: element,
          area: 'reviews'
        };
      case 'event':
      default:
        return {
          type: element,
          area: 'event_carousel'
        };
    }
  }

  function setCard(cardArgs: unknown): ReactElement {
    switch (type) {
      case 'category':
        return <CategoryCard {...(cardArgs as CategoryCardProps)} gaTags={getGaTags(type, 'card')} />;
      case 'event':
        return <EventCard {...(cardArgs as EventCardProps)} gaTags={getGaTags(type, 'card')} headingTag="h3" />;
      case 'review':
        return <ReviewCard {...(cardArgs as ReviewCardProps)} />;
      default:
        return <></>;
    }
  }

  const isStacked = (isMobile || (type === 'category' && isTablet)) && stacked;
  const canScroll = emblaApi?.canScrollPrev() || emblaApi?.canScrollNext();

  return (
    <section
      id={anchorName ? formatAnchorName(anchorName) : undefined}
      className={`carousel ${stacked ? 'carousel--stacked' : ''} carousel--${type} ${anchorName ? 'ra--offset' : ''}`}
    >
      <div className="carousel__top">
        <h2 className="carousel__title">{title ? title : ''}</h2>
        {!isStacked && canScroll && (
          <div className="carousel__nav">
            <button
              className={`carousel__button carousel__button--prev ${prevButtonEnabled ? 'theme--ra-blue' : ''}`}
              onClick={scrollPrev}
              disabled={!prevButtonEnabled}
              data-ga4-type={getGaTags(type, 'ui').type}
              data-ga4-area={getGaTags(type, 'ui').area}
            >
              <span className="sr-only">previous</span>
              <Icon icon="arrow-left" />
            </button>
            <button
              className={`carousel__button carousel__button--next ${nextButtonEnabled ? 'theme--ra-blue' : ''}`}
              onClick={scrollNext}
              disabled={!nextButtonEnabled}
              data-ga4-type={getGaTags(type, 'ui').type}
              data-ga4-area={getGaTags(type, 'ui').area}
            >
              <span className="sr-only">next</span>
              <Icon icon="arrow-right" />
            </button>
          </div>
        )}
      </div>
      <div className="carousel__embla" ref={emblaRef}>
        <ul className="carousel__list">
          {cards.slice(0, isStacked ? 3 : cards.length).map((card, idx) => (
            <li className="carousel__card" key={idx}>
              {setCard(card)}
            </li>
          ))}
        </ul>
      </div>
      {cta && (
        <div className="carousel__cta">
          <Cta {...cta} gaTags={getGaTags(type, 'ui')} />
        </div>
      )}
    </section>
  );
}
